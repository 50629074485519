import BiLogger from '../../utils/biLogger';
import { utils } from '../../utils';
import { notEligibleReasons } from 'pro-gallery/dist/es/src/components/gallery/leanGallery/isEligible';

export default class LogHelper {
  constructor(galleryWrapper, props, SENTRY_DSN, isStore) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;

    this.update = this.update.bind(this);
    this.getBiLogger = this.getBiLogger.bind(this);
    this.onAppLoaded = this.onAppLoaded.bind(this);
    this.reportBiEvent = this.reportBiEvent.bind(this);
    this.SENTRY_DSN = SENTRY_DSN;
    this.biLogger = new BiLogger(
      props.biContext,
      this.galleryWrapperProps.galleryId,
      this.galleryWrapper.siteHelper.parseViewMode(props.viewMode),
      isStore,
    );
  }

  update(props) {
    if (this.biLogger && this.galleryWrapperProps.viewMode !== props.viewMode) {
      this.biLogger.updateViewMode(
        this.galleryWrapper.siteHelper.parseViewMode(props.viewMode),
      );
    }
    if (
      this.biLogger &&
      this.galleryWrapperProps.galleryId !== props.galleryId
    ) {
      this.biLogger.updateGalleryId(props.galleryId);
    }
    this.galleryWrapperProps = props;
  }

  getBiLogger() {
    return this.biLogger;
  }

  onAppLoaded = () => {
    if (utils.isVerbose()) {
      console.log(
        '[APP LOAD DEBUG] galleryWrapper -> logHandler -> onAppLoaded',
      );
    }
    try {
      if (!utils.isSSR()) {
        //this is not happening only once as the tryToReportAppLoaded is handling the timings and is protecting of it to happen only once.
        if (utils.isVerbose()) {
          console.log(
            '[APP LOAD DEBUG] galleryWrapper -> logHandler -> onAppLoaded -> tryToReportAppLoaded is gonne be called',
          );
          console.log(
            '[APP LOAD DEBUG] galleryWrapper -> logHandler -> onAppLoaded -> this.galleryWrapperProps',
            this.galleryWrapperProps,
          );
        }
        if (this.galleryWrapperProps.tryToReportAppLoaded) {
          this.galleryWrapperProps.tryToReportAppLoaded();
        }
      }
      if (!this.appLoadBiReported) {
        if (
          this.galleryWrapperProps.appLoadBI &&
          typeof this.galleryWrapperProps.appLoadBI.loaded === 'function'
        ) {
          this.galleryWrapperProps.appLoadBI.loaded();
          this.appLoadBiReported = true;
        } else {
          const err =
            'Cannot report AppLoaded, appLoadBI.loaded function is not defined';
          console.error(err);
          this.captureMessage(err);
        }
      }
    } catch (e) {
      console.error('Could not report appLoaded', e);
    }
  };

  reportBiEvent(eventName, eventData, origin) {
    switch (eventName) {
      case 'onDownloadButtonClicked':
      case 'downloadTextItem':
        this.biLogger.trackBi('download', {
          origin: origin,
        });
        break;
      case 'onBuyNowClicked':
        this.biLogger.trackBi('buyNowClick');
        break;
      case 'onItemClicked':
        this.biLogger.trackBi('item_clicked', {
          action: eventData.styleParams.itemClick,
          mediaType: eventData.type,
          layout: utils.getGalleryLayoutName(
            eventData.styleParams.galleryLayout,
          ),
        });
        break;
      case 'onThumbnailClicked':
        this.biLogger.trackBi('item_clicked', {
          action: 'thumbnail',
          mediaType: undefined,
          layout: utils.getGalleryLayoutName(
            eventData.styleParams.galleryLayout,
          ),
        });
        break;
      case 'love':
        this.biLogger.trackBi('love', {
          origin: origin,
        });
        break;
      case 'share':
        this.biLogger.trackBi('share', {
          origin: origin,
          platform: eventData.network,
        });
        break;
      case 'item_expanded':
        this.biLogger.trackBi('item_expanded', {
          mediaType: eventData.type,
        });
        break;
      case 'gallery_rendered':
        const styles = this.galleryWrapper.siteHelper.getPGStyles();
        const {
          items,
          totalItemsCount,
        } = this.galleryWrapper.itemsHelper.pgItemsProps();
        const { wixCodeItems = [] } = this.galleryWrapperProps;
        const whyNotLean = notEligibleReasons({ items, styles }) || [];
        this.biLogger.trackBi('gallery_rendered', {
          styleParams: JSON.stringify(styles),
          numberOfItems: totalItemsCount,
          isLean: whyNotLean.length === 0,
          notLeanReason: whyNotLean.join('|') || null,
          itemSource: wixCodeItems.length > 0 ? 'corvid' : 'pgServer',
        });
        break;
      default:
        break;
    }
  }

  captureMessage(str) {
    const SENTRY_DSN = this.SENTRY_DSN;
    const options = {
      dsn: SENTRY_DSN,
      config: { environment: 'Native Component' },
    };

    try {
      this.galleryWrapperProps.raven.config(options.dsn, options.config);
      this.galleryWrapperProps.raven.captureMessage(str);
    } catch (e) {
      //
    }
  }
}
